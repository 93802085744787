import jquery from "jquery";
window.$ = jquery;

import Cookies from "js-cookie";
import Flickity from "flickity";

import "@fancyapps/fancybox/dist/jquery.fancybox.min.js";

import * as bootstrap from "bootstrap";
// comment below to fix jquery
window.bootstrap = bootstrap;
import "bootstrap-select";
import "bootstrap-datepicker";

import "nivo-slider/jquery.nivo.slider.js";
import cities from "./config/cities";
import("bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min.js");
import("bootstrap-select");
import "./jquery_ellipsis.js";
import "./share-buttons.js";
// require("social-share-button");
// require("bootstrap/alert");
// require("bootstrap/dropdown");
// require("share-buttons/dist/share-buttons.js");
// import "@fortawesome/fontawesome-free/js/all";
import Rails from "@rails/ujs";

Rails.start();

const carousel = document.querySelector(".carousel");
if (carousel) {
  new Flickity(carousel, {
    // Flickity options go here
    wrapAround: true,
    lazyLoad: true,
  });
}

// const images = require.context("../images", true);

function setDefaultCity(code) {
  const citys =
    document.querySelector(".a-form")?.getElementsByTagName("option") || [];
  for (let i = 0; i < citys.length; i++) {
    if (citys[i].value === code) {
      citys[i].selected = true;
    }
    // citys[i].selected = true;
  }
}
function sliderHeight() {
  // Function to increase the height of the slider (carousel-wrapper) based on the tallest element
  let maxHeight = 0;

  // Loop through each carousel cell and find the tallest one
  $("#flic_slide .carousel-cell").each(function() {
    let blockHeight = $(this).height();
    if (blockHeight > maxHeight) {
      maxHeight = blockHeight;
    }
  });

  // Set the height of the Flickity viewport based on the tallest carousel cell
  $("#flic_slide .flickity-viewport").height(maxHeight + 55);

  // Adjust the height of the carousel-wrapper based on window width
  if ($(window).width() <= 540) {
    $("#flic_slide .carousel-wrapper").height(maxHeight + 100);
  } else {
    $("#flic_slide .carousel-wrapper").height(maxHeight + 145);
  }
}

// Trigger the function on window resize
$(window).on('resize', function() {
  if ($("#flic_slide").length) {
    sliderHeight();
  }
});

// Optionally, trigger it once when the page loads
$(document).ready(function() {
  if ($("#flic_slide").length) {
    sliderHeight();
  }
});

$(document).ready(function () {
  //Обработка появления иконки приглашения для связи через WHATSUPP или телегу
  $(window).scroll(function (e) {
    if ($(window).scrollTop() > 300) {
      $(".messagebutton_section").show();
    } else {
      $(".messagebutton_section").hide();
    }
  });

  if ($("#flic_slide").length) sliderHeight();

  $(".fancybox").fancybox();
  $(".link").fancybox({
    openEffect: "none",
    closeEffect: "none",
    helpers: {
      media: {},
    },
  });
  $(".artic_link").fancybox({
    type: "iframe",
    width: "90%", //or whatever you want
    height: "80%",
  });
  if ($("#slider").css("display") != "none") {
    $("#slider").nivoSlider();
  }
  document.addEventListener("turbolinks:load", function () {
    $(".datepicker").datepicker();
  });
  $(".datepicker")
    .datepicker({
      language: "ru",
    })
    .on("changeDate", function (e) {
      window.location =
        window.location.pathname + `?starts_at=${e.format()}&h=${gon.cat_id}`;
    });

  // yearRange : $('.datepicker_year').attr('data-year-range')
  $(".year_date").click(function () {
    window.location =
      window.location.pathname + `?starts_at=` + $(this).attr("dateyear");
  });
  $("select").selectpicker();

  // ellipsis
  $(".eclips.cli_sh_2").ellipsis({ lines: 2 });
  $(".eclips.cli_sh_4").ellipsis({ lines: 4 });
  $(".eclips.cli_sh_5").ellipsis({ lines: 5 });
  $(".eclips.cli_sh_6").ellipsis({ lines: 6 });
  $(".eclips.cli_sh_7").ellipsis({ lines: 7 });
  $(".eclips.cli_sh_10").ellipsis({ lines: 10 });
  $(".eclips .ellip-line").click(function () {
    $(this).toggleClass("ope");
  });
  // для страниц, где есть табы
  $('a[data-toggle="pill"]').on("click", function (e) {
    var pos = 0;
    // если есть #v-pills-tabContent и ширина окна меньше 560
    if ($("#v-pills-tabContent").length > 0 && $(window).width() <= "560") {
      pos = $("#v-pills-tabContent").offset().top - 20;
    }
    $(window).scrollTop(pos);
  });
});

$(document).ready(function () {
  // $('#navrazd').hide();
  if (gon.show_modal) {
    var selector = "#link_to_addi_text" + gon.show_modal;
    $(selector).click();
  }
  if (gon.city) {
    const city = gon.city;
    Cookies.set("na_branch", city);
    Cookies.set("na_init_branch", city);
  }
  if (gon.city_ex) {
    const city_ex = gon.city_ex;
    Cookies.set("na_branch", city_ex);
    Cookies.set("na_init_branch", city_ex);
  }
  window.initCity = Cookies.get("na_init_branch") || "msk";
  window.currentCity = Cookies.get("na_branch") || window.initCity;
  window.initAcceptCity = function initAcceptCity(e) {
    Cookies.set("na_branch", window.initCity);
    Cookies.remove("na_init_branch");
    $("#initCityModalBtn").click();
  };
  window.particulAcceptCity = function particulAcceptCity(e) {
    Cookies.set("na_branch", window.initCity);
    Cookies.remove("na_init_branch");
    $("#particulCityModalBtn").click();
  };
  window.initChangeCity = function initChangeCity(e) {
    $("#initCityModalBtn").click();
    $("#cityModalBtn").click();
  };
  if (gon.city) {
    $("#particulCityModalBtn").click();
    setTimeout(function () {
      window.location = "/";
    }, 3000);
  }
  if (gon.city_ex) {
    if (gon.show_modal) {
      $("#particulCityModalBtn").click();
      window.location = "./?show_modal=" + gon.show_modal;
    } else {
      $("#particulCityModalBtn").click();
      setTimeout(function () {
        window.location = "./";
      }, 3000);
    }
  }
  $(".city-select").on("click", (event) => {
    const city = event.target.getAttribute("value");
    Cookies.set("na_branch", city);
    Cookies.remove("na_init_branch");
    if (gon.city_ext) {
      window.location = "./?city_ext=" + Cookies.get("na_branch");
    } else {
      location.reload();
      // window.location = "./";
    }
    // location.reload();
  });

  $(".city_change").on("click", (event) => {
    const city = event.target.getAttribute("branch_slug");
    Cookies.set("na_branch", city);
    Cookies.set("na_init_branch", city);
    $(".js-a-init-city").text(cities[city] && cities[city].normal);
    $("#particulCityModalBtn").click();
    $("html, body").animate({ scrollTop: "0px" }, 1000);

    setTimeout(function () {
      location.reload();
    }, 3000);
  });

  if (!Cookies.get("na_branch") && window.initCity) {
    $("#initCityModalBtn").click();
  } else {
  }
  //
  $(".js-a-current-city").text(
    cities[window.currentCity] && cities[window.currentCity].normal
  );
  $(".js-a-current-city-in").text(
    cities[window.currentCity] && cities[window.currentCity].in
  );
  $(".js-a-init-city").text(
    // cities[window.initCity] && cities[window.initCity].normal
    cities[window.currentCity] && cities[window.currentCity].normal
  );

  navItemSelect();
  hideBlock(window.currentCity);

  setDefaultCity(window.currentCity);

  // $('a.navrazd').on('click', function(event) {

  //       if($(this).attr('href').charAt(0)!='#')return true;

  //       event.preventDefault();

  //       var hash = this.hash;

  //       $('html, body').animate({scrollTop: $(hash).offset().top}, 900);

  //   });
});

$(window).on("load", function () {
  $("#navrazd").show();
  const eventImages = document.getElementsByClassName("a-event-img");
  if (eventImages.length > 0) {
    const eventPanels = document.getElementsByClassName("a-event-panel");
    const eventContent = document.getElementsByClassName("a-event-content");
    [].map.call(eventImages, (el, idx) => {
      let rgb = getAverageRGB(el);
      let font_color =
        rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186
          ? "#000000"
          : "#ffffff";
      eventPanels[
        idx
      ].style.background = `linear-gradient(to bottom, rgba(${rgb.r},${rgb.g},${rgb.b}, 0) 0%, rgba(${rgb.r},${rgb.g},${rgb.b}, 1) 100%)`;
      eventContent[idx].style.background = `rgba(${rgb.r},${rgb.g},${rgb.b})`;
      eventContent[idx].style.color = font_color;
    });
  }
});

function getAverageRGB(imgEl) {
  let blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

  context.drawImage(imgEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    console.log("security error, img on diff domain", e);
    return defaultRGB;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}

// функция подчеркивания активного пункта меню навигации
function navItemSelect() {
  let pageId = document
      .querySelector("[data-id-page]")
      ?.getAttribute("data-id-page"),
    navItem = document.querySelector(`[data-id-nav=${pageId}]`);

  if (pageId === navItem?.getAttribute("data-id-nav")) {
    navItem?.classList?.add("active-desktop-link");
  }
}

function hideBlock(value) {
  if (document.getElementById("a-blk")) {
    if (value != "msk" || value != "spb") {
      document.getElementById("a-blk").hidden = true;
    } else {
      document.getElementById("a-blk").hidden = false;
    }
  }
}

$(document).ready(function () {
  const url = window.location.href;
  const hash = url.substring(url.indexOf("#"));

  if (url.indexOf("#") > 0) {
    const activeTab = url.substring(url.indexOf("#") + 1);
    $("#" + activeTab).tab("show");
  }

  $(".nav-pills")
    .not("#navrazd")
    .find("a")
    .each(function (key, val) {
      $(val).click(function (e) {
        e.preventDefault();
        history.replaceState(null, null, "#" + this.id);
      });
    });
});
// для форм курса с выбором даты .length .eq(index)
$(document).ready(function () {
  $("#enrollment_branch_id").on("change", function () {
    // $(document).ready(function ($) {
    // alert($('#enrollment_id_branch').val() +' '+this.value);
    if (this.value == $("#enrollment_id_branch").val()) {
      $("#vivod_mesta").show();
      $("#course_price").show();
      $(".vibrat_datu").show();
    } else {
      $("#vivod_mesta").hide();
      $("#course_price").hide();
      $(".vibrat_datu").hide();
    }
    $("#enrollment_event_id").val("");
    // alert( gon.course_ar[0][5]);
  });
  $("#enrollment_date_time").on("change", function () {
    // определим номер выделенного элемента
    var select_ind = $("#enrollment_date_time option:selected").index() - 1;
    if (select_ind < 0) $("#enrollment_event_id").val("");
    else $("#enrollment_event_id").val(gon.course_ar[select_ind][5]);
  });

  var positions = [],
    currentActive = null,
    links = $(".navrazd");

  $(".anchor").each(function () {
    positions.push({
      top: $(this).position().top - 100,
      a: links.filter('[href="#' + $(this).attr("id") + '"]'),
    });
  });

  positions = positions.reverse();

  var heightHeader = $(".header.navbar").outerHeight(true);
  $("#collapsibleNavbar").css("top", heightHeader + "px");
  $("#mainy .fixed-top").css("top", heightHeader + "px");
  $(window).on("scroll", function () {
    var scrolled = $(window).scrollTop();
    var heightStop =
      $(document).outerHeight(true) - $("footer").outerHeight(true) * 2.35;
    var heightHeader = $(".header.navbar").outerHeight(true);
    for (var i = 0; i < positions.length; i++) {
      if (positions[i].top < scrolled) {
        if (currentActive !== i) {
          currentActive = i;
          links.removeClass("active");
          positions[i].a.addClass("active");
        }
        break;
      }
    }

    if (positions[positions.length - 1].top + 100 > scrolled) {
      positions[positions.length - 1].a.removeClass("active");
    } else if (
      positions[positions.length - 1].top < scrolled &&
      positions[positions.length - 2].top > scrolled
    ) {
      positions[positions.length - 1].a.addClass("active");
    }

    if (scrolled >= 350 && scrolled < heightStop) {
      $(".sidenav").css("position", "fixed");
      $(".sidenav").css("top", "5%");
      // $(".sidenav .pdf_link").css("position","absolute");
      // $(".sidenav .pdf_link").css("bottom","-5px");
      // $(".sidenav .knopkho").css("top","100px");
      // $(".sidenav .knopkho").css("position","relative");
    } else if (scrolled < 350) {
      $(".sidenav").css("position", "relative");
      $(".sidenav").css("top", "60px");
      // $(".sidenav .pdf_link").css("position","relative");
      // $(".sidenav .pdf_link").css("bottom","-5px");
      // $(".sidenav .knopkho").css("top","0px");
      // $(".sidenav .knopkho").css("position","relative");
    } else {
      $(".sidenav").css("position", "relative");
    }

    if (scrolled >= heightHeader) {
      $("#collapsibleNavbar").removeClass("show");
      $("#collapsibleNavbar").css("top", "0px");
      $("#mainy .fixed-top").css("top", "10px");
      $("#mainy .navbar-toggler").addClass("collapsed");
      $("#mainy .navbar-toggler").css("background-color", "#ffffff");
      $("#mainy .navbar-toggler").attr("aria-expanded", "false");
    } else {
      $("#collapsibleNavbar").css("top", heightHeader + "px");
      $("#mainy .fixed-top").css("top", heightHeader + "px");
      $("#mainy .navbar-toggler").css("background-color", "transparent");
    }
  });
});
